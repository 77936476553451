import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Mitr', sans-serif;
  }
  // body {
  //   background-color: #1f606f;
  //   background-position: center center;
  //   background-repeat: no-repeat;
  //   background-attachment: fixed;
  //   background-size: cover;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: auto;
  //   min-height: 100%;
  //   margin: 0;
  //   padding: 0;
  //   img {
  //     height: auto;
  //     max-width: 100%;
  //   }
  // }
  html, body{
    width: 100%;
    height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;

    background-color: #ae00ed;
    background: linear-gradient(to bottom, #ae00ed 1%,#cce7ff 100%);
  }
  #cloud-intro{
    position: relative;
    height: 100%;
    background: url(https://static.radulescu.me/examples/clouds/clouds1000.png);
    background: url(https://static.radulescu.me/examples/clouds/clouds1000.png) 0 200px,
                url(https://static.radulescu.me/examples/clouds/clouds1200_1.png) 0 300px,
                url(https://static.radulescu.me/examples/clouds/clouds1000_blur3.png) 100px 250px;
    animation: wind 20s linear infinite;
  }
  @keyframes wind{
    0% {
      background-position: 0 200px, 0 300px, 100px 250px;
    }
    100% {
      background-position: 1000px 200px, 1200px 300px, 1100px 250px;
    }
  
  }
`

export default GlobalStyle
